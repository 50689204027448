@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=PT+Sans:wght@400;700&display=swap');

html {}
:root {
  --themeColor: #727272;
  --themeColor2: #c83339;
}
/*html { font-size: 15px;}*/
/* body {color: var(--themeColor);
  font-family: 'Montserrat', sans-serif; 
 }
a, .btn, input, input[type="search"], input[type="submit"]{ webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -ms-transition: all 0.2s linear; -o-transition: all 0.2s linear; transition: all 0.2s linear; }
a { color: inherit; }
li.active a, a:hover, a:focus {text-decoration: none;color: #ef004b;outline: none;}
.btn, form input[type="submit"] {padding: 0.3rem 2.77rem;min-height: 3.33rem;color: #fff;font-size: 1.33rem;box-shadow: none;text-transform: uppercase;outline: none;border-color: transparent;font-weight: 600;background: var(--themeColor1);display: inline-flex;align-items: center;justify-content: center;}
.btn:hover, .btn:focus, form input[type="submit"]:hover, form input[type="submit"]:focus {background: var(--themeColor2);color: #fff;border-color: transparent !important;} */
/*.color1 { color: var(--themeColor1); }
.color2 { color: var(--themeColor2); }
.color3 { color: var(--themeColor3); }
.color1-hover:hover { color: var(--themeColor2); }
.color2-hover:hover { color: var(--themeColor1); }*/
.bg-cover {background-repeat: no-repeat; background-size:cover;}
.border {
  border-color:  #e1e1e1!important;
}
a {
    color: #fff;
}
a:hover {
    color: #000;
}
.header {
    background: #fff;
    border-bottom: 1px solid #dee2e6;
}
.header.header-shadow {
    z-index: 1000;
    background-color: #FFF;
}
.header-shadow nav ul li a {
    color: #808285!important;
    text-transform: uppercase;
}
.navbar {
    padding: .7rem 1rem;
}
.menuCenter li:first-child {
    margin-left: 3rem;
}
.mainWrapper {
    margin-top: 6rem;
}
.mainWrapper.noData {
    height: 120vh;
}
.custom-btn {
    background-color: #0a4476 !important;
    color: #fff !important;
}
.custom-btn :hover {
    background-color: #3a6991 !important;
}
.checkout-btn {
    background: #0a4476 !important;
}
.main_content .custom-card:hover .custom-btn {
    background-color: #3a6991 !important;
}
.checkout-btn:hover {
    background-color: #3a6991 !important;
}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
}
}
@media(min-width:1620px) {
  html { font-size: 18px; }
  .container {     max-width: 1400px;}
}
.header  {right: 0px;left: 0px;top:0px;z-index: 10;}
.header .navbar-nav li {margin-right: 3rem;}
.header .navbar-nav li a { color: #727272; text-transform: uppercase; }
.header .navbar-nav li a:hover { text-decoration: none; }

.login a { color: #727272;  }
.header-active .login a { color: #727272;  }
.home-logo img {max-width: 240px;}
.home-stone img, .about-stone img {max-width: 180px;}

.banner.bg-cover {
    min-height: 60vh;
    background-repeat: no-repeat;
    background-size: 100%;
}
.company-tag-section{
    background: #a6a6a6;
}
.ct-line-col h1 {
    font-size: 4.15rem;
}

.shopnow-col a {
    font-size: 2rem;
    line-height: 1.3;
}

.about-us-section .bg-cover {
    min-height: 60vh;
    background-size: 100%;
}



.selection-col {height: 36rem;}
.selection-col h3 {
    /* background-color: #fff5ee; */
    transform: rotate(
-180deg
);
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    font-size: 3.5rem;
    line-height: 1;
    top: 0px;
    bottom: 0px;
    z-index: 20;
    text-align: center;
    color: #727272;
}

.selection-col:after {content:'';position: absolute;background: #fff5ee;/* height: 100%; *//* width: 100%; */top: 0px;left: 15px;bottom: 0px;right: 15px;}



.selection-prod-col img {max-width: 10rem;}
.contact-us {
    min-height: calc(100vh - 38px);
}
.contact-logo {max-width: 300px;}

.contact-us-col h3 {font-size: 3.2rem;}
.contact-us-col p {
    font-size: 1.34rem;
    font-weight: 500;
}

/* Toastr */
.warning-toast, .success-toast, .error-toast {
    background-color: #51A351 !important;
    color: #fff !important;
}
.Toastify__progress-bar {
    background: transparent;
}

/* captcha */
#reload_href {
    color: #fff !important;
}

.footer {
    background-color: #f2f2f2;
    color: #0a4476;
    position: fixed;
    bottom: 0;

}

/* Second version */
.sec-1 .sec-1-title{
    font-size: 5rem;
    border-bottom: 2px solid #636161;
} 
.sec-1{
    margin-top: 56px;
    padding: 6rem 0px;
    min-height: calc(100vh - 94px);
}

.sec-2{
    margin-top: 56px;
    padding: 6rem 0px;
    min-height: calc(100vh - 94px);
}
.v2-img{
    width: 75%;
  }
  @media(max-width: 800px){

    html{
      font-size: 3vmin;
    }
    h2{
      font-size: 1.6rem;
    }
    .v2-img{
    width:100%;
  }
  p{
    font-size: .9rem;
  }
  .sec-1 .sec-1-title {
      font-size: 4rem;
  }
  .sec-1 h3{
    font-size: 1.4rem;
  }
  }
  @media(max-width: 576px){
    
    .login a, header .navbar-toggler i {
    color: #727272;
}
.header-active .navbar-toggler i{
    color: #fff;
}
}

.our-selection {
    padding: 2rem 0 5rem;
}
.our-selection .btn {
    background-color: #0a4476;
    color: #fff !important;
    border-radius: 30px;
    font-size: 18px;
    padding: 10px 20px;
    min-height: auto;
}
.our-selection .title {
    padding: 3rem 0 4rem;
}


/* Theme three css */
.v3-sec-1 h1{
  font-size: 3.7rem;
}
.v3-sec-2{
  background-image: url('/images/sec-2-bg.png');
  background-position: top center;
  background-size: cover;
  padding: 6rem 0rem;
  min-height: calc(100vh - 224px);
}
.v3-sec-3 h1{
  font-size: 4.5rem;
}
.v3-bg-stone-3{
  background-image: url('/images/bg-stone-3.jpg');
  background-size: cover;
  background-position: center center;
}
.bg-stone-1
{
 background-image: url('/images/bg-stone-1.jpg');
width:100%;
}
.bg-theme{
    background-color: #282828;
}
.v3-sec-4 .bg-theme-2 img, .v3-bg-stone-3 img{
  padding: 4rem 0rem;
}
.fs
{
  font-size: 14px;

}
.v3-img-1{
margin-top: 200px;
    margin-bottom: 123px;
    padding: 9px;
  }
@media(max-width: 576px){
.v3-sec-5 .v3-bg-stone-1 {
    background-size: cover;
    padding: 1rem 4rem;
    background-position: center center;
    min-height: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.v3-sec-5 .v3-img-1
{
  margin: 0px;
}
.v3-sec-4 .bg-theme-2 img, .v3-bg-stone-3 img {
    padding: 2rem 0rem;
}
}
@media(max-width: 400px){
  .v3-sec-4 .v3-bg-theme-2 img, .v3-bg-stone-3 img {
    padding: 0rem 0rem;
}

    
}
/* Theme four css */
.v4-text-color{
    color: #090991;
}

.v4-banner{
    min-height: 220px;
}
.banner-1
{
  background-position: center;
  width:100%;
}
.v4-sec-1{
    padding-top:8rem;
    padding-bottom:5rem; 
}
.v4-contact-us{
    padding: 8rem 0rem;
}
.stone-absolute {
    top: calc(50% - 46.5px);
    left: calc(30% - 100px);
}
.v4-img
{
    width: 100%;
}

@media(max-width: 992px){
    html{
        font-size: 2vmin;
    }
    .stone-absolute {
    top: calc(50% - 40.5px);
    left: calc(30% - 80px);
    width: 80px;
    height: auto;
}
.v4-heading{
    font-size: 2rem;
}
.v4-para{
    font-size: .9rem;
}
.v4-det-1 h5{
    font-size: 1.2rem;
}
.v4-det-1 p{
    font-size: .8rem;
}
}

@media(max-width: 767px){
    html{
        font-size: 3.4vmin;
    }
    .header {
        height: auto;
        background-color: #fff;
        border-bottom: 1px solid  #eaeaea;
    }
    .menuCenter {
        margin-top: 1rem;
    }

    .mainWrapper {
        margin-top: 4.8rem;
    }

    .our-selection .row.w-100 {
        width: auto !important;
    }
    .selection-prod-col {
        max-width: 33.3%;
    }
    .selection-prod-col img {
        max-width: 100%;
    }
    .banner.bg-cover, .about-us-section .bg-cover {
        min-height: 30vh;
        background-size: cover;
        background-position: center center;
    }
}
@media(max-width: 575px){
    .custom-row{
        flex-direction: column-reverse;
    }
}

@media(max-width: 575px){
.banner.bg-cover {
    min-height: 17vh;
}
.about-us-section .bg-cover {
    min-height: 17vh;
}
}